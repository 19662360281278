/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://kaldzhbdjzgbhoxp7c67s5ssbu.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-zs7q6glyjbfqtetx4noiskzis4",
    "aws_cognito_identity_pool_id": "us-east-1:ba3888b6-85dc-40a5-8a6c-c77808c9601d",
    "aws_cognito_region": "us-east-1",
    "oauth": {},
    "aws_mobile_analytics_app_id": "00e988dc7ff34803842e3a94e458c26f",
    "aws_mobile_analytics_app_region": "us-east-1"
};


export default awsmobile;

module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-VSYZGLYKSC"]},
    },{
      plugin: require('../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://7501d920de4b47e9861da26668557ed1@o244642.ingest.sentry.io/5480587"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
